import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

type DialogProps = {
  open: boolean;
  onClose: () => void;
  confirm: () => void;
};

const LogoutDialog: React.FC<DialogProps> = ({open, onClose, confirm}) => {

  return <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{"Logout"}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Deseja realmente sair?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" color="primary" onClick={onClose}>Cancelar</Button>
      <Button variant="outlined" color="primary" onClick={confirm} autoFocus>
        Confirmar
      </Button>
    </DialogActions>
  </Dialog>
};

export default LogoutDialog;