import { useState, useEffect } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import ArticleIcon from '@mui/icons-material/Article';
import DevicesIcon from '@mui/icons-material/Devices';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../../states/user-store";

type Props = {};

const BottomNav = (props: Props) => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const { user } = useUserStore();

  const pages = [
    {
      name: "Início",
      path: "/",
    },
    {
      name: "Projetos",
      path: "/projetos",
    },
    {
      name: "Artigos",
      path: "/artigos",
    },
  ]

  useEffect(() => {
    const path = window.location.pathname;
    const index = pages.findIndex(page => page.path === path);
    setValue(index);
  }, []);

  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        navigate(pages[newValue].path);
      }}
      sx={{ position: 'fixed', bottom: 0, width: '100%' }}
    >
      <BottomNavigationAction label="Início" icon={<HomeIcon />} />
      <BottomNavigationAction label="Projetos" icon={<DevicesIcon />} />
      {/* <BottomNavigationAction label="Artigos" icon={<ArticleIcon />} /> */}
    </BottomNavigation>
  );
};

export default BottomNav;
