import { Article } from "../../../../domain/entities/article";
import { Box, Typography } from "@mui/material";

type ArticleCardProps = {
  article: Article;
  width: string;
};

const ArticleCard: React.FC<ArticleCardProps> = ({ article, width }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "1rem",
        width: width,
      }}
    >
      <Typography variant="h4">{article.title}</Typography>
      <Typography variant="body1">{article.content}</Typography>
    </Box>
  );
};

export default ArticleCard;