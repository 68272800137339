import { useState, useEffect } from "react";
import { GetProject } from "../../../domain/usecases/project/get-project";
import {
  Box,
  Typography,
  Grid,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
  Chip,
} from "@mui/material";
import { Project } from "../../../domain/entities/project";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../../states/user-store";
import ProjectCard from "../../components/project/card/project-card";
import { toast } from "react-toastify";
import useWindowSize from "../../../application/hooks/useScreenSize";

type Props = {
  loadProjects: GetProject;
};

type Filters = {
  tags: string[];
};

type ProjectsInfo = {
  projects: Project[];
  tags: string[];
};

const ProjectListPage: React.FC<Props> = ({ loadProjects }) => {
  const [projectsInfo, setProjectsInfo] = useState<ProjectsInfo>({
    projects: [],
    tags: [],
  });
  const [filters, setFilters] = useState<Filters>({ tags: [] });
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useUserStore();

  const navigate = useNavigate();

  const { width } = useWindowSize();

  const handleLoadProjects = async () => {
    try {
      setLoading(true);
      var projects = await loadProjects.getAll();
      var tags: string[] = [];

      if (projects) {
        projects = projects.sort((a, b) =>
          a.createdAt < b.createdAt ? 1 : -1
        );
      }

      for (const proj of projects) {
        tags.push(...proj.tags);
      }

      // remove duplicates
      tags = tags.filter((item, index) => tags.indexOf(item) === index);

      setProjectsInfo({
        projects: projects.filter((proj) => proj.shown),
        tags: tags,
      });
    } catch (error) {
      console.log(error);
      toast.error("Erro ao carregar projetos");
    } finally {
      setLoading(false);
    }
  };

  const goToCreateProject = () => {
    navigate("/projetos/create");
  };

  const addTag = (e: any) => {
    const tag = e.target.value;
    if (tag !== "") {
      setFilters({ tags: [...filters.tags, tag] });
    }
  };

  const removeTag = (tagName: string) => {
    setFilters({
      tags: filters.tags.filter((tag: string) => tag !== tagName),
    });
  };

  useEffect(() => {
    handleLoadProjects();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1em",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant={width && width < 600 ? "h5" : "h3"}>
          Projetos
        </Typography>
        {user.admin && (
          <Button
            variant="outlined"
            color="primary"
            onClick={goToCreateProject}
          >
            Criar projeto
          </Button>
        )}
      </Box>
      <Box>
        <Typography variant="h6">Filtros</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <InputLabel id="tags">Tags</InputLabel>
          <Select
            value={"0"}
            label="Tags"
            onChange={addTag}
            sx={{ width: "fit-content", minWidth: "100px", maxWidth: "200px" }}
            inputProps={{
              name: "tags",
              id: "tags",
            }}
          >
            <MenuItem value="0">Selecione</MenuItem>
            {projectsInfo.tags.map((tag: string) => (
              <MenuItem value={tag} key={tag}>
                {tag}
              </MenuItem>
            ))}
          </Select>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5rem",
              flexWrap: "wrap",
              marginTop: "0.5rem",
            }}
          >
            {filters.tags.map((tag: string) => (
              <Chip
                key={tag}
                label={tag}
                onDelete={() => removeTag(tag)}
                color="primary"
                variant="outlined"
              />
            ))}
          </Box>
        </Box>
      </Box>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {projectsInfo.projects.map((project: Project) =>
            filters.tags.length === 0 ||
            project.tags.some((tag: string) => filters.tags.includes(tag)) ? (
              <Grid item xs={12} sm={6} md={4} key={project._id}>
                <ProjectCard project={project} admin={user.admin} />
              </Grid>
            ) : null
          )}
        </Grid>
      )}
    </Box>
  );
};

export default ProjectListPage;
