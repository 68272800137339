import { useParams } from "react-router-dom"
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { RemoteGetProject } from "../../../../data/usecases/project/remote-get-project";
import SingleProjectPage from "../../../../presentation/pages/project/project";
import { AppConfig } from "../../../appconfig";
import { RemoteDeleteProject } from "../../../../data/usecases/project/remote-delete-project";
import { RemoteGetComment } from "../../../../data/usecases/comment/remote-get-comment";

export const SingleProjectFactory = () => {
    const { id } = useParams();
    const axiosHttpClient = new AxiosHttpClient();
    const remoteGetProject = new RemoteGetProject(
        "/projects",
        axiosHttpClient
    );
    const remoteDeleteProject = new RemoteDeleteProject(
        "/projects",
        axiosHttpClient
    );
    const remoteGetComments = new RemoteGetComment(
        "/comments",
        axiosHttpClient
    )

    return <SingleProjectPage getProject={remoteGetProject} deleteProject={remoteDeleteProject} projectId={id || ""} />
}