import { useState } from "react";
import { Box, Button, TextField, CircularProgress } from "@mui/material";
import { eStyles } from "../../../application/global/e-styles";
import { LoginUser } from "../../../domain/usecases/auth/login-user";
import { useUserStore } from "../../states/user-store";
import { User } from "../../../domain/entities/user";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

type Props = {
  authentication: LoginUser;
}

const Login: React.FC<Props> = ({authentication}) => {
  const [form, setForm] = useState({
    username: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { setUser } = useUserStore();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    
    const { username, password } = form;

    try {
      const response = await authentication.login({
        username,
        password,
      });
      
      if (response) {
        setUser(User.userToObject(response));
        navigate("/");
        toast.success("Login efetuado com sucesso!");
      }
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
        margin: 0,
        minHeight: "70vh",
      }}
    >
      <h1>Login</h1>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: "300px",
            maxHeight: "90%",
          }}
        >
          <TextField
            id="username"
            name="username"
            label="Username"
            variant="outlined"
            value={form.username}
            onChange={handleChange}
            sx={eStyles.input}
          />
          <TextField
            id="password"
            name="password"
            label="Password"
            variant="outlined"
            value={form.password}
            onChange={handleChange}
            sx={eStyles.input}
            inputProps={{
              type: "password",
            }}
          />
          <Link to="/register" style={{
            color: "#000",
            width: "fit-content",
          }}>
            Criar conta
          </Link>
          <Button
            variant="outlined"
            type="submit"
            sx={{
              backgroundColor: "#fff",
              transition: "background-color 0.2s",
              border: "1px solid #000",
              color: "#000",

              "&:hover": {
                backgroundColor: "#000",
                color: "#fff",
              },
            }}
          >
            {loading ? <CircularProgress size={24} /> : "Login"}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default Login;
