import { Avatar, Box, Typography } from "@mui/material";
import { Comment } from "../../../domain/entities/comment";
import { geraTempoPassado } from "../../../application/global/funcoesGlobais";

type Props = {
  comment: Comment;
};

const CommentTile: React.FC<Props> = ({ comment }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px",
        borderRadius: "10px",
        backgroundColor: "#f5f5f5",
        marginBottom: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Avatar
          alt={comment.user.name}
          src={comment.user.avatar || "https://api.dicebear.com/avatar.svg"}
          sx={{ width: 32, height: 32 }}
        />
        <Box>
          <Box sx={{
            display: "flex",
            gap: "5px",
          }}>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {comment.user.name || "Anônimo"}
          </Typography>
          <Typography variant="body2" sx={{ color: "#666666" }}>
            @{comment.user.username || "anonimo"}
          </Typography>
          </Box>
          <Typography variant="body2" sx={{ color: "#666666" }}>
            {comment.body}
          </Typography>
        </Box>
      </Box>
      <Typography variant="body2" sx={{ color: "#666666" }}>
        {geraTempoPassado(comment.createdAt)}
      </Typography>
    </Box>
  );
};

export default CommentTile;
