import { User } from "../../../domain/entities/user";
import { RegisterUser } from "../../../domain/usecases/auth/register-user";
import { AppConfig } from "../../../main/appconfig";
import { HttpPostClient } from "../../protocols/http/http-post-client";

export class RemoteRegisterUser implements RegisterUser {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async register({
    name,
    username,
    password,
  }: RegisterUser.Params): Promise<User> {
    try {
      const apiToken = AppConfig.API_TOKEN;
      const httpResponse = await this.httpPostClient.post({
        url: this.url,
        body: {
          name: name,
          username: username,
          password: password,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiToken}`,
        },
      });

      const {
        _id,
        name: person_name,
        username: user_name,
        avatar,
        admin,
      } = httpResponse?.data ? httpResponse.data : httpResponse;

      if (!_id || !person_name || !user_name)
        throw new Error(httpResponse.data?.error || "Erro ao cadastrar-se.");

      const user = {
        _id: _id,
        username: user_name,
        name: person_name,
        avatar: avatar,
        admin: admin,
      };
      return user;
    } catch (error) {
      console.log(error);

      throw error;
    }
  }
}
