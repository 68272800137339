import React from "react";
import { Box, Button } from "@mui/material";

type Props = {
  editAction: () => void;
  confirmDelete: () => void;
};

const ProjectActions: React.FC<Props> = ({ editAction, confirmDelete }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        gap: "1rem",
        width: "100%",
      }}
    >
      <Button variant="contained" color="info" onClick={editAction}>
        Editar
      </Button>
      <Button variant="contained" color="error" onClick={confirmDelete}>
        Deletar
      </Button>
    </Box>
  );
};

export default ProjectActions;
