import { RemoteGetProject } from "../../../../data/usecases/project/remote-get-project";
import { RemotePostProject } from "../../../../data/usecases/project/remote-post-project";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import CreateProjectPage from "../../../../presentation/pages/project/create";
import { AppConfig } from "../../../appconfig";

export const CreateProjectFactory = () => {
    const axiosHttpClient = new AxiosHttpClient();
    const remoteCreateProject = new RemotePostProject(
        "/projects",
        axiosHttpClient
    );

    return <CreateProjectPage postProject={remoteCreateProject} />
}