import { Chip, Box } from "@mui/material";

type TagProps = {
  tagList: string[];
}

const TagsRow: React.FC<TagProps> = ({tagList}) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: '0.5rem' }}>
      {tagList.map((tag, index) => (
        <Chip key={index} label={tag} variant="outlined" />
      ))}
    </Box>
  )
}

export default TagsRow;