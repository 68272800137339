import { User } from "./user";

export class Comment {
  _id: string;
  idPost: string;
  body: string;
  user: User;
  postType: string;
  createdAt: Date;

  constructor(
    id: string,
    idPost: string,
    body: string,
    user: User,
    postType: string,
    createdAt: Date
  ) {
    this._id = id;
    this.idPost = idPost;
    this.body = body;
    this.user = user;
    this.postType = postType;
    this.createdAt = createdAt;
  }

  static create(
    id: string,
    idPost: string,
    body: string,
    user: User,
    postType: string,
    createdAt: Date = new Date()
  ): Comment {
    return new Comment(id, idPost, body, user, postType, createdAt);
  }

  static createFromObject(obj: any): Comment {
    return new Comment(obj.id, obj.idPost, obj.body, obj.user, obj.postType, obj.createdAt);
  }

  static createFromObjects(objs: any[]): Comment[] {
    return objs.map((obj) => this.createFromObject(obj));
  }

  static createFromJSON(json: string): Comment {
    return this.createFromObject(JSON.parse(json));
  }

  static userToObject(obj: Comment): any {
    return {
      id: obj._id,
      idPost: obj.idPost,
      body: obj.body,
      user: obj.user,
      postType: obj.postType,
      createdAt: obj.createdAt,
    };
  }
}
