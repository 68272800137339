import { Box, Typography, Avatar } from "@mui/material";
import { Comment } from "../../../domain/entities/comment";
import { geraStringData } from "../../../application/global/funcoesGlobais";
import { useEffect, useState } from "react";
import { PostComment } from "../../../domain/usecases/comment/post-comment";
import { GetComment } from "../../../domain/usecases/comment/get-comment";
import { toast } from "react-toastify";
import PostCommentComponent from "./post-comment";
import { User } from "../../../domain/entities/user";
import CommentTile from "./comment-tile";
const { useUserStore } = require("../../states/user-store");

type Props = {
  getComments: GetComment;
  postComment: PostComment;
  postId: string;
};

const CommentSection: React.FC<Props> = ({
  getComments,
  postComment,
  postId,
}) => {
  const [comments, setComments] = useState<Comment[]>([]);
  const { user } = useUserStore();

  const loadComments = async () => {
    try {
      const comments = await getComments.getByPostId({
        postId: postId,
      });
      setComments(comments);
    } catch (e) {
      toast.error("Erro ao carregar comentários");
    }
  };

  const postNewComment = async (body: string) => {
    try {
      const comment = await postComment.post({
        idPost: postId,
        body: body,
        user: {
          ...user,
          _id: user.id,
          avatar: user.avatar || "https://api.dicebear.com/avatar.svg",
        },
        postType: "1",
      });
      if (comment._id?.length > 0) {
        toast.success("Comentário postado com sucesso");
        loadComments();
        return true;
      } else
        toast.error("Erro ao postar comentário, tente novamente mais tarde");
      return false;
    } catch (e) {
      toast.error("Erro ao postar comentário");
      return false;
    }
  };

  useEffect(() => {
    loadComments();
  }, []);

  return (
    <Box>
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        Comentários
      </Typography>
      {comments && comments.length > 0 ? (
        comments.map((comment) => (
          <CommentTile comment={comment} key={comment._id} />
        ))
      ) : (
        <Typography variant="body2" sx={{ color: "#666666" }}>
          Nenhum comentário encontrado
        </Typography>
      )}
      {user && user.id ? (
        <PostCommentComponent post={postNewComment} />
      ) : (
        <Typography variant="body2" sx={{ color: "#666666" }}>
          Faça login para comentar
        </Typography>
      )}
    </Box>
  );
};

export default CommentSection;
