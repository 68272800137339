import { RemoteGetArticle } from "../../../../data/usecases/article/remote-get-article";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { AppConfig } from "../../../appconfig";
import { useParams } from "react-router-dom";
import SingleArticlePage from "../../../../presentation/pages/article/article";

export const ArticlePageFactory = () => {
  const { id, slug } = useParams<{ id: string; slug: string }>();
  const axiosHttpClient = new AxiosHttpClient();
  const remoteGetArticle = new RemoteGetArticle(
    `/articles/s/${slug}`,
    axiosHttpClient
  );

  return (
    <SingleArticlePage getArticle={remoteGetArticle} articleId={id ?? ""} />
  );
};
