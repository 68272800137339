import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
        margin: 0,
        minHeight: "70vh",
      }}
    >
      <h1>404</h1>
      <h2>Página não encontrada</h2>
      <Link to="/">Voltar para a página inicial</Link>
    </Box>
  );
};

export default NotFound;
