import { Comment } from "../../../domain/entities/comment";
import { GetComment } from "../../../domain/usecases/comment/get-comment";
import { AppConfig } from "../../../main/appconfig";
import { HttpPostClient } from "../../protocols/http/http-post-client";

export class RemoteGetComment implements GetComment {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async getByPostId ({ postId }: GetComment.Params): Promise<Comment[]> {
    try {
      const url = `${this.url}/p/${postId}`;
      const apiToken = AppConfig.API_TOKEN;
      const httpResponse = await this.httpPostClient.get({
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiToken}`,  
        },
      });

      const comments: Comment[] = httpResponse?.data
        ? httpResponse.data
        : httpResponse;

      return comments.map(
        (comment) =>
          new Comment(
            comment._id,
            comment.idPost,
            comment.body,
            comment.user,
            comment.postType,
            comment.createdAt
          )
      );
    } catch (error) {
      throw new Error("Erro ao obter comentários");
    }
  }
  
}