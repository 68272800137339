import { useState } from "react";
import { User } from "../../../domain/entities/user";
import { Box, Menu, Avatar, Typography, MenuItem } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useUserStore } from "../../states/user-store";

type Props = {
  handleLog: () => void;
};

const UserMenu: React.FC<Props> = ({ handleLog }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { user } = useUserStore();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    user && (
      <Box>
        <Avatar
          src={user.avatar || "https://api.dicebear.com/avatar.svg"}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{
            cursor: "pointer",
          }}
        />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ horizontal: "center", vertical: "top" }}
        >
          {user.id ? (
            <>
              {/* <MenuItem>Perfil</MenuItem> */}
              <MenuItem onClick={handleLog}>Sair</MenuItem>
            </>
          ) : (
            <MenuItem onClick={handleLog}>Entrar</MenuItem>
          )}
        </Menu>
      </Box>
    )
  );
};

export default UserMenu;
