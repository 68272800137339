import React from "react";
import { Box, Grid, Typography } from "@mui/material";

const AboutPage: React.FC = () => {
  return (
    <Box sx={{ flexGrow: 1, padding: "0 1em" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Sobre</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Olá, sou Eric, o autor deste blog. Este espaço é dedicado à
            divulgação de projetos, experiências e insights relacionados ao
            desenvolvimento de software. Ao abordar tópicos que vão desde
            códigos em JavaScript, React e Node.js até dicas práticas para
            aprimorar habilidades de programação, meu objetivo é contribuir com
            a comunidade de desenvolvedores.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            A motivação para compartilhar conhecimento surge da busca contínua
            por aprendizado e da gratidão pela comunidade de desenvolvedores que
            tem sido fundamental em minha trajetória. Este blog serve como um
            registro da minha evolução, oferecendo recursos úteis para outros
            desenvolvedores em busca de aprimoramento.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Encorajo a interação, seja por meio de comentários nos posts ou pelo
            contato pelas redes sociais. Vamos colaborar nessa jornada de
            aprendizado e crescimento!
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutPage;
