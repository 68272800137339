import { useState, useRef } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { PostProject } from "../../../domain/usecases/project/post-project";
import { toast } from "react-toastify";
import { useUserStore } from "../../states/user-store";
import { useNavigate } from "react-router-dom";

type Props = {
  postProject: PostProject;
};

const CreateProjectPage: React.FC<Props> = ({ postProject }: Props) => {
  const [form, setForm] = useState({
    title: "",
    description: "",
    screenshots: [] as string[],
    contributors: [] as string[],
    tags: [] as string[],
  });

  // vamos usar o useRef para pegar o valor do input de screenshot
  const screenshotRef = useRef<HTMLInputElement>(null);

  const { user } = useUserStore();
  const navigate = useNavigate();

  const handleForm = (e: any) => {
    const { name, value } = e.target;
    
    switch(name) {
      case "contributors":
      case "tags":
        setForm({ ...form, [name]: value.split(",") });
        break;
      default:
        setForm({ ...form, [name]: value });
        break;
    }
  };

  const handleSubmit = async () => {
    const response = await postProject.post({
      ...form,
      owner: user.name,
      contributors: form.contributors.map(c => c.trim()).filter((c) => c.length > 0),
      tags: form.tags.map(t => t.trim()).filter((c) => c.length > 0),
    });

    if (!response) {
      toast.error("Erro ao criar projeto!");
      return;
    }

    toast.success("Projeto criado com sucesso!");
    navigate("/projetos");
  };

  const handleAddScreenshot = (url: string) => {
    if(url.length === 0 || !url.includes("https://")) return;
    setForm({ ...form, screenshots: [...form.screenshots, url] });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
        padding: "1em",
        width: "100%",
        height: "100%",
      }}
    >
      <Typography variant="h3">Create Project</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: "100%",
          maxWidth: "500px",
        }}
      >
        <TextField
          label="Title"
          variant="outlined"
          name="title"
          value={form.title}
          onChange={handleForm}
        />
        <TextField
          label="Description"
          variant="outlined"
          name="description"
          value={form.description}
          onChange={handleForm}
          multiline={true}
          rows={20}
        />
        <TextField
          label="Contributors"
          variant="outlined"
          name="contributors"
          value={form.contributors}
          onChange={handleForm}
        />
        <TextField
          label="Tags"
          variant="outlined"
          name="tags"
          value={form.tags}
          onChange={handleForm}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">Screenshots</Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              label="Screenshot URL"
              variant="outlined"
              name="screenshot"
              inputRef={screenshotRef}
              sx={{
                flexGrow: 1,
                marginRight: "1rem",
              }}
            />
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                handleAddScreenshot(screenshotRef.current?.value as string)
              }
            >
              Adicionar
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            {form.screenshots.map((screenshot, index) => (
              <img
                key={index}
                src={screenshot}
                alt={`Screenshot ${index}`}
                style={{ maxWidth: "100%", borderRadius: "5px" }}
              />
            ))}
          </Box>
        </Box>
        <Button
          type="submit"
          variant="outlined"
          color="primary"
          onClick={handleSubmit}
        >
          Create
        </Button>
      </Box>
    </Box>
  );
};

export default CreateProjectPage;
