import { User } from "../../../domain/entities/user";
import { LoginUser } from "../../../domain/usecases/auth/login-user";
import { HttpPostClient } from "../../protocols/http/http-post-client";
import { useUserStore } from "../../../presentation/states/user-store";
import { AppConfig } from "../../../main/appconfig";

export class RemoteLoginUser implements LoginUser {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async login({ username, password }: LoginUser.Params): Promise<User> {
    try {
      const apiToken = AppConfig.API_TOKEN;
      const httpResponse = await this.httpPostClient.post({
        url: this.url,
        body: {
          username: username,
          password: password,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiToken}`,
        },
      });

      // vamos fazer um tratamento para o caso de o servidor retornar um erro
      if (httpResponse.status !== 200) {
        throw new Error(httpResponse.body.message);
      }

      const {
        _id,
        name,
        username: user_name,
        avatar,
        admin,
      } = httpResponse?.data ? httpResponse.data[0] : httpResponse;

      if(!_id || !name || !user_name) throw new Error("Erro ao fazer login");

      const user = {
        _id: _id,
        username: user_name,
        name: name,
        avatar: avatar,
        admin: admin,
      };

      //useUserStore.setState(User.userToObject(user));

      return user;
    } catch (error) {
      console.log(error);
      
      throw new Error("Erro ao fazer login");
    }
  }
}
