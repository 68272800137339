import axios, { AxiosResponse } from "axios";
import { HttpPostClient } from "../../data/protocols/http/http-post-client";
import { AppConfig } from "../../main/appconfig";

export class AxiosHttpClient implements HttpPostClient {
  baseUrl: string = AppConfig.DEBUG_MODE
    ? AppConfig.BASE_URL_DEBUG
    : AppConfig.BASE_URL;

  async post(params: HttpPostClient.Params): Promise<any> {
    let axiosResponse: AxiosResponse;

    try {
      axiosResponse = await axios.post(this.baseUrl + params.url, params.body, {
        headers: params.headers,
      });
    } catch (error: any) {
      axiosResponse = error.response;
    }

    return axiosResponse;
  }

  async get(params: HttpPostClient.Params): Promise<any> {
    let axiosResponse: AxiosResponse;

    try {
      axiosResponse = await axios.get(this.baseUrl + params.url, {
        headers: params.headers,
      });
    } catch (error: any) {
      console.log(error)
      axiosResponse = error.response;
    }

    return axiosResponse;
  }

  async put(params: HttpPostClient.Params): Promise<any> {
    let axiosResponse: AxiosResponse;

    try {
      axiosResponse = await axios.put(this.baseUrl + params.url, params.body, {
        headers: params.headers,
      });
    } catch (error: any) {
      axiosResponse = error.response;
    }

    return axiosResponse;
  }

  async delete(params: HttpPostClient.Params): Promise<any> {
    let axiosResponse: AxiosResponse;

    try {
      axiosResponse = await axios.delete(this.baseUrl + params.url, {
        headers: params.headers,
      });
    } catch (error: any) {
      axiosResponse = error.response;
    }

    return axiosResponse;
  }
}
