import { create } from "zustand";

type UserState = {
  user: {
    id: string;
    name: string;
    username: string;
    avatar: string;
    admin: boolean;
  };
  setUser: (user: UserState["user"]) => void;
  logout: () => void;
};

export const useUserStore = create<UserState>((set) => ({
  user: {
    id: "",
    name: "",
    username: "",
    avatar: "",
    admin: false,
  },
  setUser: (user) => set({ user }),
  logout: () => set({ user: { id: "", name: "", username: "", avatar: "", admin: false } }),
}));