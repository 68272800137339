import React from "react";
import { Link } from "react-router-dom";
import { articleListTemplate } from "../../../tests/templates/articles/template";
import ArticleCard from "../../components/article/card/articleCard";
import { Box, Typography, BottomNavigation, BottomNavigationAction } from "@mui/material";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { geraSlug } from "../../../application/global/funcoesGlobais";

const ArticlesPage: React.FC = () => {
  const artigos = articleListTemplate();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "1em",
        gap: "1rem",
        border: "1px solid black",
      }}
    >
      <Typography variant="h3">Artigos</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          border: "1px solid black",
          width: "100%",
        }}
      >
        {artigos.map((artigo, index) => (
          <Link
            to={`/artigos/${artigo._id}/${geraSlug(artigo)}`}
            style={{
              textDecoration: "none",
              color: "black",
              padding: "0",
              border: "1px solid pink",
            }}
            key={index}
          >
            <ArticleCard article={artigo} width="400px" />
          </Link>
        ))}
      </Box>
    </Box>
  );
};

export default ArticlesPage;
