import { Typography } from "@mui/material";

type Props = {
  content: string;
};

const showTextWithImages = (text: string) => {
  const pattern = /\(img_(.*?)\)\[(.*?)\]/g;
  let match;
  let lastIndex = 0;
  const pieces = [];

  while ((match = pattern.exec(text)) !== null) {
    const [fullMatch, imageName, imageUrl] = match;
    const oldText = text.slice(lastIndex, match.index);
    pieces.push(oldText);
    pieces.push(<img key={match.index} src={imageUrl} alt={imageName} style={{
      maxWidth: "100%",
      height: "auto",
      display: "block",
      margin: "0 auto",
    }}/>);
    lastIndex = pattern.lastIndex;
  }

  pieces.push(text.slice(lastIndex));

  return pieces;
};

const ArticleContent: React.FC<Props> = ({ content }) => {
  const contentWithImages = showTextWithImages(content);
  return <pre><Typography variant="body1" sx={{
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    display: "flex",
    flexDirection: "column"
  }}>{contentWithImages}</Typography></pre>;
};

export default ArticleContent;
