import { Comment } from "../../../domain/entities/comment";
import { PostComment } from "../../../domain/usecases/comment/post-comment";
import { AppConfig } from "../../../main/appconfig";
import { HttpPostClient } from "../../protocols/http/http-post-client";

export class RemotePostComment implements PostComment {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async post({
    body,
    idPost,
    user,
    postType,
  }: PostComment.Params): Promise<Comment> {
    try {
      const url = this.url;
      const apiToken = AppConfig.API_TOKEN;
      const httpResponse = await this.httpPostClient.post({
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiToken}`,
        },
        body: {
          body,
          idPost,
          user,
          postType,
        },
      });

      const comment: Comment = httpResponse?.data
        ? httpResponse.data
        : httpResponse;

      return comment;
    } catch (error) {
      throw new Error("Não foi possível publicar o comentário");
    }
  }
}
