import Slider from "react-slick";
import { Box } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// type Props = {
//   images: string[],
//   width: number | undefined
// }

const ProjectGallery = ({ images, width }) => {
  // using react-slick
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          arrows: false,
        },
      },
    ],
  };


  return (
    <Box
      sx={{
        padding: width && width < 600 ? "0" : "0 1em",
        maxWidth: "100%",

        // change arrows color
        ".slick-prev:before, .slick-next:before": {
          color: "black",
        },
      }}
    >
      <Slider {...settings}>
        {images.map((image) => (
          <Box
            key={image}
            sx={{
              display: "flex !important",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <img
              src={image}
              alt={"imagem"}
              style={{
                maxHeight: width && width < 600 ? "300px" : "500px",
                maxWidth: "100%",
              }}
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default ProjectGallery;
