import { Box, Typography } from "@mui/material";
import { articleListTemplate } from "../../../../tests/templates/articles/template";
import { Link } from "react-router-dom";
import { geraSlug } from "../../../../application/global/funcoesGlobais";
import ArticleCard from "../card/articleCard";

type MenuProps = {
  title: string;
  orderBy: string;
};

const ArticlesSideMenu: React.FC<MenuProps> = ({ title, orderBy }) => {
  const articles = articleListTemplate();

  if(orderBy === "createdAt") articles.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());

  const geraLink = (article: any) => `/artigos/${article.id}/${geraSlug(article.title)}`;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        border: "1px solid blue",
        width: '300px'
      }}
    >
      <Typography variant="h5">{title || "Artigos"}</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          width: '100%',
          backgroundColor: "pink"
        }}
      >
        {articles.map((article, index) => (
          <Link
            to={geraLink(article)}
            style={{
              textDecoration: "none",
              color: "black",
              padding: "0",
              border: "1px solid pink",
              width: '100%'
            }}
            key={index}
          >
            <ArticleCard article={article} width="100%" />
          </Link>
        ))}
      </Box>
    </Box>
  );
};

export default ArticlesSideMenu;
