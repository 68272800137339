import { RemoteGetComment } from "../../../data/usecases/comment/remote-get-comment";
import { RemotePostComment } from "../../../data/usecases/comment/remote-post-comment";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { AppConfig } from "../../appconfig";
import CommentSection from "../../../presentation/components/comment/comment-section";

type Props = {
  postId: string;
}

export const CommentSectionFactory: React.FC<Props> = ({ postId }) => {
  const axiosHttpClient = new AxiosHttpClient();
  const remoteLoadComments = new RemoteGetComment(
    "/comments",
    axiosHttpClient
  );
  const remotePostComment = new RemotePostComment(
    "comments",
    axiosHttpClient
  );


  return <CommentSection getComments={remoteLoadComments} postComment={remotePostComment} postId={postId} />;
};
