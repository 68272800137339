import { RemoteLoginUser } from "../../../../data/usecases/auth/remote-login";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import Login from "../../../../presentation/pages/auth/login";
import { AppConfig } from "../../../appconfig";

export const LoginFactory = () => {
  const axiosHttpClient = new AxiosHttpClient();
  const remoteAuthentication = new RemoteLoginUser(
    "/users/login",
    axiosHttpClient
  );

  return <Login authentication={remoteAuthentication} />;
};
