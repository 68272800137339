import { RemoteGetProject } from "../../../../data/usecases/project/remote-get-project";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import ProjectListPage from "../../../../presentation/pages/project/list";

export const ProjectListFactory = () => {
    const axiosHttpClient = new AxiosHttpClient();
    const remoteLoadProjects = new RemoteGetProject(
        "/projects",
        axiosHttpClient
    );

    return <ProjectListPage loadProjects={remoteLoadProjects} />
}