import { RemoteRegisterUser } from "../../../../data/usecases/auth/remote-register";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import Register from "../../../../presentation/pages/auth/register";

export const RegisterFactory = () => {
  const axiosHttpClient = new AxiosHttpClient();
  const remoteAuthentication = new RemoteRegisterUser(
    "/users/register",
    axiosHttpClient
  );

  return <Register authentication={remoteAuthentication} />;
};
