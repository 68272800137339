import { useState } from "react";
import { Box, CircularProgress, Avatar, TextField, Button } from "@mui/material";
import { useUserStore } from "../../states/user-store";
import SendIcon from "@mui/icons-material/Send";

type Props = {
  post: any;
};

const PostCommentComponent: React.FC<Props> = ({ post }) => {
  // esse componente vai ser responsável por permitir que o usuário poste um comentário
  const [comment, setComment] = useState<string>(""); // o comentário que o usuário está escrevendo
  const [posting, setPosting] = useState<boolean>(false); // se o usuário está postando ou não
  const { user } = useUserStore();

  const handlePostComment = async () => {
    // aqui vai ser feita a requisição para postar o comentário

    // o método já vem pronto, só preciso validar se o usuário escreveu algo
    if (comment.trim() === "") {
      return;
    }

    // aqui eu vou fazer a requisição para postar o comentário
    setPosting(true);
    const posted = await post(comment);

    if(posted) {
      setComment("");
    }
    setPosting(false);
  };

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
        <Avatar
          src={user.avatar || "https://api.dicebear.com/avatar.svg"}
          sx={{ width: 40, height: 40, mr: 2 }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TextField
            fullWidth
            label="Escreva um comentário"
            onChange={(e) => {
              setComment(e.target.value);
            }}
          />
          <Button
            variant="contained"
            sx={{ ml: 1, width: 100, height: 56 }}
            onClick={handlePostComment}
          >
            {posting ? <CircularProgress /> : <SendIcon />}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PostCommentComponent;
