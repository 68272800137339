import { DeleteProject } from "../../../domain/usecases/project/delete-project";
import { AppConfig } from "../../../main/appconfig";
import { HttpPostClient } from "../../protocols/http/http-post-client";

export class RemoteDeleteProject implements DeleteProject {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}
  

  async delete({id}: DeleteProject.Params): Promise<boolean> {
    try {
      const apiToken = AppConfig.API_TOKEN;
      const httpResponse = await this.httpPostClient.delete({
        url: `${this.url}/${id}`,
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${apiToken}`
        },
      });

      const result = httpResponse.data;

      return result || false;
    } catch(error) {
      throw new Error("Erro ao deletar projeto");
    }
  }
}