export class Article {
    _id: string;
    title: string;
    content: string;
    createdAt: Date;
    creator?: string;

    constructor(id: string, title: string, content: string, createdAt: Date = new Date(), creator: string = 'anonymous') {
        this._id = id;
        this.title = title;
        this.content = content;
        this.createdAt = createdAt;
        this.creator = creator;
    }

    static create(id: string, title: string, content: string, createdAt: Date, creator: string = 'anonymous'): Article {
        return new Article(id, title, content, createdAt, creator);
    }

    static createFromObject(obj: any): Article {
        return new Article(obj.id, obj.title, obj.content, obj.createdAt);
    }

    static createFromObjects(objs: any[]): Article[] {
        return objs.map(obj => this.createFromObject(obj));
    }

    static createFromJSON(json: string): Article {
        return this.createFromObject(JSON.parse(json));
    }
}
