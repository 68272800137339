import { Typography, Container, Box } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { Link } from "react-router-dom";

const Home: React.FC = () => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h2">e-melo</Typography>
      <Link to="/projetos">Projetos</Link>
      <Link to="/login">Login</Link>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "1em"
        }}
      >
        <Typography variant="h5">Contato</Typography>
        <Box
          sx={{
            display: "flex",
            gap: ".5em",
          }}
        >
          <EmailIcon />
          <Link to="mailto:contato@emelo.dev.br">contato@emelo.dev.br</Link>
        </Box>
      </Box>
    </Container>
  );
};

export default Home;
