import { RemoteGetProject } from "../../../../data/usecases/project/remote-get-project";
import { RemotePostProject } from "../../../../data/usecases/project/remote-post-project";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import EditProjectPage from "../../../../presentation/pages/project/edit";
import { useParams } from "react-router-dom";

export const EditProjectFactory = () => {
  const { id } = useParams();
  const axiosHttpClient = new AxiosHttpClient();
  const remoteEditProject = new RemotePostProject(
    `/projects`,
    axiosHttpClient
  );
  const remoteGetProject = new RemoteGetProject(
    `/projects`,
    axiosHttpClient
  )

  return <EditProjectPage editProject={remoteEditProject} getProject={remoteGetProject} projectId={id || ""} />;
};
