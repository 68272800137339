import { createTheme } from "@mui/material";

export const eStyles = {
  input: {
    width: "100%",
    color: "black",

    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#000"
      },
    },

    "& .MuiFormLabel-root": {
      "&.Mui-focused": {
        color: "#000",
      },
    },
  },
};

export const theme = createTheme({
  // major: "#000",
  // minor: "#fff",
  // background: "#fff",
  // text: "#000",

  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#fff",
    },
  },
});

// using theme:
// import { ThemeProvider } from "@mui/material/styles";
// import { theme } from "./e-styles";
//
// <ThemeProvider theme={theme}>
