import { Article } from "../../../domain/entities/article";

const articleList = [
    Article.create(
        "1",
        "Article 1",
        "Content 1",
        new Date()
    ),
    Article.create(
        "2",
        "Article 2",
        "Content 2",
        new Date()
    ),
    Article.create(
        "3",
        "Article 3",
        "Content 3",
        new Date()
    ),
    Article.create(
        "4",
        "Article 4",
        "Content 4",
        new Date()
    ),
    Article.create(
        "5",
        "Article 5",
        "Content 5",
        new Date()
    ),
    Article.create(
        "6",
        "Article 6",
        "Content 6",
        new Date()
    ),
    Article.create(
        "7",
        "Article 7",
        "Content 7",
        new Date()
    ),
    Article.create(
        "8",
        "Article 8",
        "Content 8",
        new Date()
    ),
    Article.create(
        "9",
        "Article 9",
        "Content 9",
        new Date()
    ),
    Article.create(
        "10",
        "Article 10",
        "Content 10",
        new Date()
    ),
]

export const articleListTemplate = () => {
    return articleList;
}

export const articleTemplate = () => {
    return Article.create(
        "1",
        "Article 1",
        "Content 1",
        new Date()
    );
}

export const getArticleTemplate = (id: String) => {
    return articleList.find(article => article._id === id);
}