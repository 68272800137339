export class User {
  _id: string;
  username: string;
  name: string;
  avatar: string;
  admin: Boolean;

  constructor(
    id: string,
    username: string,
    name: string,
    avatar: string,
    admin: Boolean = false
  ) {
    this._id = id;
    this.username = username;
    this.name = name;
    this.avatar = avatar;
    this.admin = admin;
  }

  static create(
    id: string,
    username: string,
    name: string,
    avatar: string,
    admin: Boolean = false
  ): User {
    return new User(id, username, name, avatar, admin);
  }

  static createFromObject(obj: any): User {
    return new User(obj.id, obj.username, obj.name, obj.avatar, obj.admin);
  }

  static createFromObjects(objs: any[]): User[] {
    return objs.map((obj) => this.createFromObject(obj));
  }

  static createFromJSON(json: string): User {
    return this.createFromObject(JSON.parse(json));
  }

  static userToObject(obj: User): any {
    return {
      id: obj._id,
      username: obj.username,
      name: obj.name,
      avatar: obj.avatar,
      admin: obj.admin,
    };
  }
}
