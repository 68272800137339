import { Container, Typography, Box } from "@mui/material";
import ArticlesSideMenu from "../../components/article/menu/articlesMenu";
import ScrollToTopOnMount from "../../../application/global/scroll-to-top";
import { GetArticle } from "../../../domain/usecases/article/get-article";
import { Article } from "../../../domain/entities/article";
import { useEffect, useState } from "react";
import ArticleContent from "./article-content";

type Props = {
  articleId: string,
  getArticle: GetArticle;
}

const SingleArticlePage: React.FC<Props> = ({getArticle, articleId}) => {
  const [article, setArticle] = useState<Article | null>(null);

  const loadArticle = async () => {
    try {
      const article = await getArticle.get({
        id: Number(articleId)
      });
  
      if(article !== null) {
        setArticle(article);
      }
    } catch (error) {
      alert("Erro ao carregar artigo");
      console.log(error);
    }
  }

  useEffect(() => {
    if(article === null) {
      loadArticle();
    }
  }, [article, loadArticle]);

  return article ? (
    <Box
      sx={{
        display: "flex",
        border: "1px solid black",
        padding: "0 1rem",
        gap: "1rem",
      }}
    >
      <ScrollToTopOnMount />
      <Container sx={{
        width: '90%',
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        border: "1px solid black",
        margin: 0,
      }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h3">{article.title}</Typography>
          <Typography variant="body1">
            {article.createdAt ? new Date(article.createdAt).toLocaleDateString() : ""}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
            border: "1px solid black",
            width: "100%",
          }}
        >
          <ArticleContent content={article.content} />
        </Box>
      </Container>
      <ArticlesSideMenu title="Últimos artigos" orderBy="createdAt" />
    </Box>
  ) : (
    <Typography variant="h2">Artigo não encontrado</Typography>
  );
};

export default SingleArticlePage;